<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_item.formulario.codigo')"
        type="number"
        obrigatorio
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_item.formulario.descricao')"
        :max="200"
        trim
        obrigatorio
      />
    </v-form>

    <v-divider class="my-5" />

    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>

      <botao-padrao
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import TipoItemService from '@common/services/cadastros/TipoItemService';
import { TipoItemModel } from '@common/models/cadastros/TipoItemModel';
import helpers from '@common/utils/helpers';

export default {
  // eslint-disable-next-line
  props: ['id'],

  data() {
    return {
      valido: false,
      form: new TipoItemModel({}),
      opcoes: {
        Booleano: helpers.BoleanoEnum,
      },
    };
  },

  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.tipo_item.titulos.editar');

      return this.$t('modulos.tipo_item.titulos.novo');
    },

    tooltipBotaoSalvar: function () {
      if (this.valido) return '';

      return this.$t('modulos.tipo_item.formulario_invalido');
    },
  },

  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },

  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'TipoItem', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'TipoItem', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },

  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      TipoItemService.buscar(this.id)
        .then((res) => {
          this.form = new TipoItemModel(res.data);
          this.valido = true;
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },

    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      TipoItemService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t('modulos.tipo_item.cadastro_sucesso'));

          this.$router.push({ name: 'tipo-item' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'tipo-item' });
      });
    },
  },
};
</script>
